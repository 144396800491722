import React from 'react'
import TableComponentsContainer from '../../../../Components/Tables/ReactstrapTables/TableComponents'

export default function TableComponents() {
  return (
    <div className='page-body'>
        <TableComponentsContainer/>
    </div>
  )
}
