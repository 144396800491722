import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage2() {
    return (
        <CommonErrorPage
            title='401'
            color='warning'
        />
    )
}
