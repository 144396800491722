import React from 'react'
import FeatherIconsContainer from '../../../Components/Icons/FeatherIcons'

export default function FeatherIcons() {
  return (
    <div className='page-body'>
        <FeatherIconsContainer/>
    </div>
  )
}
