//bookmark
export const bookmarkViewsNav = [
    { id: '1', title: 'Created by me' },
    { id: '2', title: 'Favourites' },
    { id: '3', title: 'Shared with me' },
    { id: '4', title: 'My bookmark' },
]

export const bookmarkTagsNav = [
    { id: '5', title: 'notification' },
    { id: '6', title: 'Newsletter' },
    { id: '7', title: 'Business' },
    { id: '8', title: 'Holidays' },
    { id: '9', title: 'Important' },
    { id: '10', title: 'Organization' },
];