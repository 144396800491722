import React from 'react'
import HelperClassContainer from '../../../Components/UiKits/HelperClasses'

export default function HelperClasses() {
    return (
        <div className='page-body'>
            <HelperClassContainer />
        </div>
    )
}
