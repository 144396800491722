import React from 'react'
import TagAndPillsContainer from '../../../Components/UiKits/Tag&Pills'

export default function TagAndPills() {
    return (
        <div className='page-body'>
            <TagAndPillsContainer />
        </div>
    )
}
