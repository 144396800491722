import React from 'react'
import AccordionContainer from '../../../Components/UiKits/Accordion'

export default function Accordion() {
  return (
    <div className='page-body'>
        <AccordionContainer/>
    </div>
  )
}
