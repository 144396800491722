import React from 'react'
import SweetAlertContainer from '../../../Components/BonusUi/SweetAlert'

export default function SweetAlert() {
  return (
    <div className='page-body'>
        <SweetAlertContainer/>
    </div>
  )
}
