import React from 'react'
import ProjectCreateContainer from '../../../../Components/Applications/Project/ProjectCreate'

export default function ProjectCreate() {
  return (
    <div className='page-body'>
        <ProjectCreateContainer/>
    </div>
  )
}
