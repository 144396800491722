import React from 'react'
import AddProductContainer from '../../../../Components/Applications/ECommerce/AddProduct'

export default function AddProduct() {
  return (
    <div className='page-body'>
        <AddProductContainer/>
    </div>
  )
}
