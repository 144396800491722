import React from 'react'
import BasicCardContainer from '../../../Components/BonusUi/BasicCard'

export default function BasicCard() {
  return (
    <div className='page-body'>
        <BasicCardContainer/>
    </div>
  )
}
