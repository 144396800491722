import React from 'react'
import CategoryContainer from '../../../../Components/Applications/ECommerce/CategoryPage'

export default function CategoryPage() {
  return (
    <div className='page-body'>
        <CategoryContainer/>
    </div>
  )
}
