import React from 'react'
import CardsViewContainer from '../../../../Components/Miscellaneous/JobSearch/CardsView'

export default function CardsView() {
  return (
    <div className='page-body'>
        <CardsViewContainer/>
    </div>
  )
}
