import React from 'react'
import ECommerceDashboard from '../../../Components/Dashboard/ECommerce'

export default function ECommerce() {
  return (
    <div className='page-body'>
        <ECommerceDashboard/>
    </div>
  )
}
