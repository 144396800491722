import React from 'react'
import FileManagerContainer from '../../../Components/Applications/FileManager'

export default function FileManager() {
  return (
    <div className='page-body'>
        <FileManagerContainer/>
    </div>
  )
}
