import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage1() {
    return (
        <CommonErrorPage
            title='400'
            color='primary'
            error1
        />
    )
}
