import React from 'react'
import ToastsContainer from '../../../Components/BonusUi/Toasts'

export default function Toasts() {
  return (
    <div className='page-body'>
        <ToastsContainer/>
    </div>
  )
}
