import React from 'react'
import FormWizard1Container from '../../../../Components/Forms/FormLayout/FormWizard1'

export default function FormWizard1() {
  return (
    <div className='page-body'>
        <FormWizard1Container/>
    </div>
  )
}
