import React from 'react'
import Invoice5Container from '../../../../../Components/Applications/ECommerce/Invoices/Invoice5'

export default function Invoice5() {
  return (
    <div className='page-body'>
        <Invoice5Container/>
    </div>
  )
}
