import React from 'react'
import JobDetailsContainer from '../../../../Components/Miscellaneous/JobSearch/JobDetails'

export default function JobDetails() {
    return (
        <div className='page-body'>
            <JobDetailsContainer />
        </div>
    )
}
