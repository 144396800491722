import React from 'react'
import RatingContainer from '../../../Components/BonusUi/Rating'

export default function Rating() {
  return (
    <div className='page-body'>
        <RatingContainer/>
    </div>
  )
}
