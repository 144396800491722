import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage6() {
    return (
        <CommonErrorPage
            title='503'
            color='info'
        />
    )
}
