import React from 'react'
import FormValidationContainer from '../../../../Components/Forms/FormControls/FormValidation'

export default function FormValidation() {
  return (
    <div className='page-body'>
        <FormValidationContainer/>
    </div>
  )
}
