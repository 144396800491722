import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage4() {
    return (
        <CommonErrorPage
            title='404'
            color='danger'
        />
    )
}
