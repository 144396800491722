import React from 'react'
import PricingContainer from '../../../../Components/Applications/ECommerce/Pricing'

export default function Pricing() {
  return (
    <div className='page-body'>
        <PricingContainer/>
    </div>
  )
}
