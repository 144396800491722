import React from 'react'
import ListViewContainer from '../../../../Components/Miscellaneous/JobSearch/ListView'

export default function ListView() {
  return (
    <div className='page-body'>
        <ListViewContainer/>
    </div>
  )
}
