import React from 'react'
import AlertContainer from '../../../Components/UiKits/Alert'

export default function Alert() {
  return (
    <div className='page-body'>
        <AlertContainer/>
    </div>
  )
}
