import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage3() {
    return (
        <CommonErrorPage
            title='403'
            color='success'
        />
    )
}
