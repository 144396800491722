import React from 'react'
import TimelineContainer from '../../../Components/BonusUi/Timeline'

export default function Timeline() {
  return (
    <div className='page-body'>
        <TimelineContainer/>
    </div>
  )
}
