import React from 'react'
import TypeaheadContainer from '../../../../Components/Forms/FormWidgets/Typeahead'

export default function Typeahead() {
    return (
        <div className='page-body'>
            <TypeaheadContainer />
        </div>
    )
}
