import React from 'react'
import EditProfileContainer from '../../../../Components/Applications/Users/UsersEdit'

export default function EditProfile() {
  return (
    <div className='page-body'>
        <EditProfileContainer/>
    </div>
  )
}
