import React from 'react'
import OrderHistoryContainer from '../../../../Components/Applications/ECommerce/OrderHistory'

export default function OrderHistory() {
  return (
    <div className='page-body'>
        <OrderHistoryContainer/>
    </div>
  )
}
