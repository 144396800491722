import React from 'react'
import TooltipContainer from '../../../Components/UiKits/Tooltip'

export default function Tooltip() {
  return (
    <div className='page-body'>
        <TooltipContainer/>
    </div>
  )
}
