import React from 'react'
import CheckboxAndRadioContainer from '../../../../Components/Forms/FormControls/CheckboxAndRadio'

export default function CheckboxAndRadio() {
  return (
    <div className='page-body'>
        <CheckboxAndRadioContainer/>
    </div>
  )
}
