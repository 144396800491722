import { TableColumn } from "react-data-table-component";
import { AddRowsTableProp, DeleteRowData, FilterDataTableProp } from "../../../Types/Tables.type";
import { Href } from "../../../Utils/Constants";
import { CustomNameImage } from "./BasicInit";
import { Badges, LI, UL } from "../../../AbstractElements";

export const addRowsSubTitle = [
    {
        text: "New rows can be added to a DataTable using the",
        code: " row.add() "
    },
    {
        text: "API method. Simply call the API function with the data for the new row (be it an array or object). Multiple rows can be added using the",
        code: " rows.add() "
    },
    {
        text: "method (note the plural). Data can likewise be updated with the",
        code: " row().data() "
    },
    {
        text: "and",
        code: " row().remove() "
    },
    {
        text: "methods."
    },
    {
        text: "Note that in order to see the new row in the table you must call the",
        code: " draw() "
    },
    {
        text: "method, which is easily done through the chaining that the DataTables API employs."
    }
]

export const childrenRowsSubTitle = [
    {
        text: "The DataTables API has a number of methods for attaching child rows to a parent row in the DataTable. This can be used to show additional information about a row, useful for cases where you wish to convey more information about a row than there is space for in the host table."
    }
];

export const rowsSelectionSubTitle = [
    {
        text: "It can be useful to provide the user with the option to select rows in a DataTable. This can be done by using a click event to add / remove a class on the table rows. The",
        code: " rows().data() "
    },
    {
        text: "method can then be used to get the data for the selected rows. In this case it is simply counting the number of selected rows, but much more complex interactions can easily be developed."
    }
]

export const customFilteringSearchSubTitle = [
    {
        text: "This example shows a search being performed on the age column in the data, based upon two inputs."
    }
];

export const addRowsTableData = [
    {
        column1: 101,
        column2: 10.2,
        column3: 10.3,
        column4: 10.4,
        column5: 10.5,
    },
    {
        column1: 111,
        column2: 11.2,
        column3: 11.3,
        column4: 11.4,
        column5: 11.5,
    },
    {
        column1: 121,
        column2: 12.2,
        column3: 12.3,
        column4: 12.4,
        column5: 12.5,
    },
];

export const addRowsTableColumns: TableColumn<AddRowsTableProp>[] = [
    {
        name: "Column1",
        selector: (row) => row.column1,
        sortable: true,
        center: false,
    },
    {
        name: "Column2",
        selector: (row) => row.column2,
        sortable: true,
        center: false,
    },
    {
        name: "Column3",
        selector: (row) => row.column3,
        sortable: true,
        center: false,
    },
    {
        name: "Column4",
        cell: (row) => row.column4,
        sortable: true,
        center: false,
    },
    {
        name: "Column5",
        cell: (row) => row.column5,
        sortable: true,
        center: false,
    },
];

export const deleteRowData: DeleteRowData[] = [
    {
        name: "Airi Satou",
        id: 1,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Angelica Ramos",
        id: 2,
        job: "Chief Executive Officer (CEO)",
        companyName: "Tata Co.",
        invoiceNumber: "#VC47",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2009/10/09",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$1.200.000,00",
    },
    {
        name: "Bradley Greer",
        id: 3,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#JH41",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2012/10/13",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$132.000,00",
    },
    {
        name: "Bruno Nash",
        id: 4,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#UY38",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">1.4%</i>,
        date: "2011/05/03",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$163.500,00",
    },
    {
        name: "Caesar Vance",
        id: 5,
        job: "Pre-Sales Support",
        companyName: "New York",
        invoiceNumber: "#CV21",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2011/12/12",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$106.450,00",
    },
    {
        name: "Cara Stevens",
        id: 6,
        job: "Sales Assistant",
        companyName: "New York",
        invoiceNumber: "#TY46",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.5%</i>,
        date: "2011/12/06",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$145.600,00",
    },

    {
        name: "Cedric Kelly",
        id: 7,
        job: "Senior Javascript Developer",
        companyName: "Edinburgh",
        invoiceNumber: "#UH22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2012/03/29",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$433.060,00",
    },

    {
        name: "Charde Marshall",
        id: 8,
        job: "Regional Director",
        companyName: "Google Co.",
        invoiceNumber: "#FV36",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2008/10/16",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$470.600,00",
    },

    {
        name: "Colleen Hurst",
        id: 9,
        job: "Javascript Developer",
        companyName: "Google Co.",
        invoiceNumber: "#NB39",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">2.8%</i>,
        date: "2009/09/15",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$205.500,00",
    },
    {
        name: "Dai Rios",
        id: 10,
        job: "Personnel Lead",
        companyName: "Edinburgh",
        invoiceNumber: "#YT35",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2012/09/26",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$217.500,00",
    },
    {
        name: "Donna Snider",
        id: 11,
        job: "Customer Support",
        companyName: "New York",
        invoiceNumber: "#GD27",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.5%</i>,
        date: "2011/01/25",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$112.000,00",
    },
    {
        name: "Doris Wilder",
        id: 12,
        job: "Sales Assistant",
        companyName: "Sidney",
        invoiceNumber: "#BH23",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2010/09/20",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$85.600,00",
    },
    {
        name: "Finn Camacho",
        id: 13,
        job: "Support Engineer",
        companyName: "Google Co.",
        invoiceNumber: "#YU47",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2009/07/07",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$87.500,00",
    },
    {
        name: "Fiona Green",
        id: 14,
        job: "Chief Operating Officer (COO)",
        companyName: "Infosys Ltd.",
        invoiceNumber: "#GF48",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2010/03/11",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$850.000,00",
    },
    {
        name: "Garrett Winters",
        id: 15,
        job: "Accountant",
        companyName: "Edinburgh",
        invoiceNumber: "#FG63",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2011/07/25",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$170.750,00",
    },
    {
        name: "Gavin Cortez",
        id: 16,
        job: "Team Leader",
        companyName: "Google Co.",
        invoiceNumber: "#SW22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2008/10/26",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$235.500,00",
    },
    {
        name: "Gavin Joyce",
        id: 17,
        job: "Developer",
        companyName: "Edinburgh",
        invoiceNumber: "#TH42",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2010/12/22",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$92.575,00",
    },
    {
        name: "Airi Satou",
        id: 18,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Bruno Nash",
        id: 19,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#UY38",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">1.4%</i>,
        date: "2011/05/03",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$163.500,00",
    },
    {
        name: "Gavin Cortez",
        id: 20,
        job: "Team Leader",
        companyName: "Google Co.",
        invoiceNumber: "#SW22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2008/10/26",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$235.500,00",
    },
    {
        name: "Finn Camacho",
        id: 21,
        job: "Support Engineer",
        companyName: "Google Co.",
        invoiceNumber: "#YU47",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2009/07/07",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$87.500,00",
    },
    {
        name: "Doris Wilder",
        id: 22,
        job: "Sales Assistant",
        companyName: "Sidney",
        invoiceNumber: "#BH23",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2010/09/20",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$85.600,00",
    },
    {
        name: "Airi Satou",
        id: 23,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Charde Marshall",
        id: 24,
        job: "Regional Director",
        companyName: "Google Co.",
        invoiceNumber: "#FV36",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2008/10/16",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$470.600,00",
    },
    {
        name: "Airi Satou",
        id: 25,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Angelica Ramos",
        id: 26,
        job: "Chief Executive Officer (CEO)",
        companyName: "Tata Co.",
        invoiceNumber: "#VC47",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2009/10/09",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$1.200.000,00",
    },
    {
        name: "Bradley Greer",
        id: 27,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#JH41",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2012/10/13",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$132.000,00",
    },
    {
        name: "Bruno Nash",
        id: 28,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#UY38",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">1.4%</i>,
        date: "2011/05/03",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$163.500,00",
    },
    {
        name: "Caesar Vance",
        id: 29,
        job: "Pre-Sales Support",
        companyName: "New York",
        invoiceNumber: "#CV21",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2011/12/12",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$106.450,00",
    },
    {
        name: "Cara Stevens",
        id: 30,
        job: "Sales Assistant",
        companyName: "New York",
        invoiceNumber: "#TY46",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.5%</i>,
        date: "2011/12/06",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$145.600,00",
    },

    {
        name: "Cedric Kelly",
        id: 31,
        job: "Senior Javascript Developer",
        companyName: "Edinburgh",
        invoiceNumber: "#UH22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2012/03/29",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$433.060,00",
    },

    {
        name: "Charde Marshall",
        id: 32,
        job: "Regional Director",
        companyName: "Google Co.",
        invoiceNumber: "#FV36",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2008/10/16",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$470.600,00",
    },

    {
        name: "Colleen Hurst",
        id: 33,
        job: "Javascript Developer",
        companyName: "Google Co.",
        invoiceNumber: "#NB39",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">2.8%</i>,
        date: "2009/09/15",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$205.500,00",
    },

    {
        name: "Dai Rios",
        id: 34,
        job: "Personnel Lead",
        companyName: "Edinburgh",
        invoiceNumber: "#YT35",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2012/09/26",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$217.500,00",
    },

    {
        name: "Donna Snider",
        id: 35,
        job: "Customer Support",
        companyName: "New York",
        invoiceNumber: "#GD27",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.5%</i>,
        date: "2011/01/25",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$112.000,00",
    },

    {
        name: "Doris Wilder",
        id: 36,
        job: "Sales Assistant",
        companyName: "Sidney",
        invoiceNumber: "#BH23",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2010/09/20",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$85.600,00",
    },

    {
        name: "Finn Camacho",
        id: 37,
        job: "Support Engineer",
        companyName: "Google Co.",
        invoiceNumber: "#YU47",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2009/07/07",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$87.500,00",
    },

    {
        name: "Fiona Green",
        id: 38,
        job: "Chief Operating Officer (COO)",
        companyName: "Infosys Ltd.",
        invoiceNumber: "#GF48",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2010/03/11",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$850.000,00",
    },

    {
        name: "Garrett Winters",
        id: 39,
        job: "Accountant",
        companyName: "Edinburgh",
        invoiceNumber: "#FG63",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2011/07/25",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$170.750,00",
    },

    {
        name: "Gavin Cortez",
        id: 40,
        job: "Team Leader",
        companyName: "Google Co.",
        invoiceNumber: "#SW22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2008/10/26",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$235.500,00",
    },

    {
        name: "Gavin Joyce",
        id: 41,
        job: "Developer",
        companyName: "Edinburgh",
        invoiceNumber: "#TH42",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">9.8%</i>,
        date: "2010/12/22",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$92.575,00",
    },
    {
        name: "Airi Satou",
        id: 42,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Bruno Nash",
        id: 43,
        job: "Software Engineer",
        companyName: "Tata Co.",
        invoiceNumber: "#UY38",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">1.4%</i>,
        date: "2011/05/03",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$163.500,00",
    },
    {
        name: "Gavin Cortez",
        id: 44,
        job: "Team Leader",
        companyName: "Google Co.",
        invoiceNumber: "#SW22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2008/10/26",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$235.500,00",
    },
    {
        name: "Finn Camacho",
        id: 45,
        job: "Support Engineer",
        companyName: "Google Co.",
        invoiceNumber: "#YU47",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2009/07/07",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$87.500,00",
    },
    {
        name: "Doris Wilder",
        id: 46,
        job: "Sales Assistant",
        companyName: "Sidney",
        invoiceNumber: "#BH23",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2010/09/20",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$85.600,00",
    },
    {
        name: "Airi Satou",
        id: 47,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Charde Marshall",
        id: 48,
        job: "Regional Director",
        companyName: "Google Co.",
        invoiceNumber: "#FV36",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2008/10/16",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$470.600,00",
    },
    {
        name: "Colleen Hurst",
        id: 49,
        job: "Javascript Developer",
        companyName: "Google Co.",
        invoiceNumber: "#NB39",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">2.8%</i>,
        date: "2009/09/15",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$205.500,00",
    },

    {
        name: "Dai Rios",
        id: 50,
        job: "Personnel Lead",
        companyName: "Edinburgh",
        invoiceNumber: "#YT35",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2012/09/26",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$217.500,00",
    },
    {
        name: "Gavin Cortez",
        id: 51,
        job: "Team Leader",
        companyName: "Google Co.",
        invoiceNumber: "#SW22",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2008/10/26",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$235.500,00",
    },
    {
        name: "Finn Camacho",
        id: 52,
        job: "Support Engineer",
        companyName: "Google Co.",
        invoiceNumber: "#YU47",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2009/07/07",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$87.500,00",
    },
    {
        name: "Doris Wilder",
        id: 53,
        job: "Sales Assistant",
        companyName: "Sidney",
        invoiceNumber: "#BH23",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">5.6%</i>,
        date: "2010/09/20",
        priority: <Badges color="light-primary">High</Badges>,
        budget: "$85.600,00",
    },
    {
        name: "Airi Satou",
        id: 54,
        job: "Accountant",
        companyName: "Google Inc.",
        invoiceNumber: "#TY33",
        credit: (
            <i className="icofont icofont-arrow-down me-1 text-danger">2.5%1</i>
        ),
        date: "2008/11/28",
        priority: <Badges color="light-danger">Urgent</Badges>,
        budget: "$162.700,00",
    },
    {
        name: "Charde Marshall",
        id: 55,
        job: "Regional Director",
        companyName: "Google Co.",
        invoiceNumber: "#FV36",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">1.4%</i>,
        date: "2008/10/16",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$470.600,00",
    },
    {
        name: "Colleen Hurst",
        id: 56,
        job: "Javascript Developer",
        companyName: "Google Co.",
        invoiceNumber: "#NB39",
        credit: <i className="icofont icofont-arrow-up me-1 text-success">2.8%</i>,
        date: "2009/09/15",
        priority: <Badges color="light-warning">Medium</Badges>,
        budget: "$205.500,00",
    },

    {
        name: "Dai Rios",
        id: 57,
        job: "Personnel Lead",
        companyName: "Edinburgh",
        invoiceNumber: "#YT35",
        credit: <i className="icofont icofont-arrow-down me-1 text-danger">2.8%</i>,
        date: "2012/09/26",
        priority: <Badges color="light-success">Low</Badges>,
        budget: "$217.500,00",
    },

];

export const deleteDataTableColumns: TableColumn<DeleteRowData>[] = [
    {
        name: "Employee Name",
        selector: (row) => row["name"],
        sortable: true,
        center: false,
    },
    {
        name: "Job Designation",
        selector: (row) => row["job"],
        sortable: true,
        center: false,
    },
    {
        name: "Company Name",
        selector: (row) => row["companyName"],
        sortable: true,
        center: false,
    },
    {
        name: "Invoice No.",
        selector: (row) => row["invoiceNumber"],
        sortable: true,
        center: false,
    },
    {
        name: "Credit/Debit",
        cell: (row) => row["credit"],
        sortable: true,
        center: false,
    },
    {
        name: "Date",
        selector: (row) => row["date"],
        sortable: true,
        center: false,
    },
    {
        name: "Priority",
        cell: (row) => row["priority"],
        sortable: true,
        center: false,
    },
    {
        name: "Budget",
        selector: (row) => row["budget"],
        sortable: true,
        center: false,
    },
    {
        name: "action",
        sortable: false,
        center: false,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="edit">
                    <a href={Href}>
                        <i className="icon-pencil-alt" />
                    </a>
                </LI>
                <LI className="delete">
                    <a href={Href}>
                        <i className="icon-trash" />
                    </a>
                </LI>
            </UL>
        )
    },
];


export const filterDataTableData: FilterDataTableProp[] = [
    {
        id: 1,
        image: '7.jpg',
        name: 'Airi Satou',
        position: "Accountant",
        office: "Tokyo",
        age: 33,
        startDate: "2008/11/28",
        salary: "$162,700",
    },
    {
        id: 2,
        image: '11.png',
        name: 'Ashton Cox',
        position: "Chief Executive Officer (CEO)",
        office: "London",
        age: 47,
        startDate: "2009/10/09",
        salary: "$1,200,000",
    },
    {
        id: 3,
        image: '7.jpg',
        name: 'Bradley Greer',
        position: "Junior Technical Author",
        office: "San Francisco",
        age: 66,
        startDate: "2009/01/12",
        salary: "$86,000",
    },
    {
        id: 4,
        image: '7.jpg',
        name: 'Brenden Wagner',
        position: "Software Engineer",
        office: "London",
        age: 41,
        startDate: "2012/10/13",
        salary: "$132,000",
    },
    {
        id: 5,
        image: '7.jpg',
        name: 'Brielle Williamson',
        position: "Software Engineer",
        office: "San Francisco",
        age: 28,
        startDate: "2011/06/07",
        salary: "$206,850",
    },
    {
        id: 6,
        image: '7.jpg',
        name: 'Bruno Nash',
        position: "Integration Specialist",
        office: "New York",
        age: 61,
        startDate: "2012/12/02",
        salary: "$372,000",
    },
    {
        id: 7,
        image: '7.jpg',
        name: 'Caesar Vance',
        position: "Software Engineer",
        office: "London",
        age: 38,
        startDate: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 8,
        image: '7.jpg',
        name: 'Cara Stevens',
        position: "Pre-Sales Support",
        office: "New York",
        age: 21,
        startDate: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 9,
        image: '3.png',
        name: 'Cara Stevens',
        position: "Sales Assistant",
        office: "New York",
        age: 46,
        startDate: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 10,
        image: '3.png',
        name: 'Cedric Kelly',
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        age: 22,
        startDate: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 11,
        image: '7.jpg',
        name: 'Charde Marshall',
        position: "Regional Director",
        office: "San Francisco",
        age: 36,
        startDate: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 12,
        image: '7.jpg',
        name: 'Colleen Hurst',
        position: "Javascript Developer",
        office: "San Francisco",
        age: 39,
        startDate: "2009/09/15",
        salary: "$205,500",
    },
    {
        id: 13,
        image: '7.jpg',
        name: 'Dai Rios',
        position: "Personnel Lead",
        office: "Edinburgh",
        age: 35,
        startDate: "2012/09/26",
        salary: "$217,500",
    },
    {
        id: 14,
        image: '7.jpg',
        name: 'Donna Snider',
        position: "Customer Support",
        office: "New York",
        age: 27,
        startDate: "2011/01/25",
        salary: "$112,000",
    },
    {
        id: 15,
        image: '7.jpg',
        name: 'Doris Wilder',
        position: "Sales Assistant",
        office: "Sidney",
        age: 23,
        startDate: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 16,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Support Engineer",
        office: "San Francisco",
        age: 47,
        startDate: "2009/07/07",
        salary: "$87,500",
    },
    {
        id: 17,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Chief Operating Officer (COO)",
        office: "San Francisco",
        age: 48,
        startDate: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 18,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Accountant",
        office: "Tokyo",
        age: 63,
        startDate: "2011/07/25",
        salary: "$170,750",
    },
    {
        id: 19,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Team Leader",
        office: "San Francisco",
        age: 22,
        startDate: "2008/10/26",
        salary: "$235,500",
    },
    {
        id: 20,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Developer",
        office: "Edinburgh",
        age: 42,
        startDate: "2010/12/22",
        salary: "$92,575",
    },
    {
        id: 21,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Systems Administrator",
        office: "New York",
        age: 59,
        startDate: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 22,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Senior Marketing Designer",
        office: "London",
        age: 43,
        startDate: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 23,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Regional Director",
        office: "London",
        age: 47,
        startDate: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 24,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Sales Assistant",
        office: "San Francisco",
        age: 59,
        startDate: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 25,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Secretary",
        office: "San Francisco",
        age: 41,
        startDate: "2010/02/12",
        salary: "$109,850",
    },
    {
        id: 26,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Office Manager",
        office: "San Francisco",
        age: 51,
        startDate: "2008/12/16",
        salary: "$164,500",
    },
    {
        id: 27,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Director",
        office: "New York",
        age: 65,
        startDate: "2008/09/26",
        salary: "$645,750",
    },
    {
        id: 28,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Office Manager",
        office: "London",
        age: 30,
        startDate: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 29,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Development Lead",
        office: "New York",
        age: 30,
        startDate: "2011/09/03",
        salary: "$345,000",
    },
    {
        id: 30,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Sales Assistant",
        office: "Sidney",
        age: 23,
        startDate: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 31,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Support Engineer",
        office: "San Francisco",
        age: 47,
        startDate: "2009/07/07",
        salary: "$87,500",
    },
    {
        id: 32,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Chief Operating Officer (COO)",
        office: "San Francisco",
        age: 48,
        startDate: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 33,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Accountant",
        office: "Tokyo",
        age: 63,
        startDate: "2011/07/25",
        salary: "$170,750",
    },
    {
        id: 34,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Team Leader",
        office: "San Francisco",
        age: 22,
        startDate: "2008/10/26",
        salary: "$235,500",
    },
    {
        id: 35,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Developer",
        office: "Edinburgh",
        age: 42,
        startDate: "2010/12/22",
        salary: "$92,575",
    },
    {
        id: 36,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Systems Administrator",
        office: "New York",
        age: 59,
        startDate: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 37,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Senior Marketing Designer",
        office: "London",
        age: 43,
        startDate: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 38,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Regional Director",
        office: "London",
        age: 47,
        startDate: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 39,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Sales Assistant",
        office: "San Francisco",
        age: 59,
        startDate: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 40,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Secretary",
        office: "San Francisco",
        age: 41,
        startDate: "2010/02/12",
        salary: "$109,850",
    },
    {
        id: 41,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Office Manager",
        office: "San Francisco",
        age: 51,
        startDate: "2008/12/16",
        salary: "$164,500",
    },
    {
        id: 42,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Director",
        office: "New York",
        age: 65,
        startDate: "2008/09/26",
        salary: "$645,750",
    },
    {
        id: 43,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Office Manager",
        office: "London",
        age: 30,
        startDate: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 44,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Development Lead",
        office: "New York",
        age: 30,
        startDate: "2011/09/03",
        salary: "$345,000",
    },
    {
        id: 45,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Sales Assistant",
        office: "Sidney",
        age: 23,
        startDate: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 46,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Support Engineer",
        office: "San Francisco",
        age: 47,
        startDate: "2009/07/07",
        salary: "$87,500",
    },
    {
        id: 47,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Chief Operating Officer (COO)",
        office: "San Francisco",
        age: 48,
        startDate: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 48,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Accountant",
        office: "Tokyo",
        age: 63,
        startDate: "2011/07/25",
        salary: "$170,750",
    },
    {
        id: 49,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Team Leader",
        office: "San Francisco",
        age: 22,
        startDate: "2008/10/26",
        salary: "$235,500",
    },
    {
        id: 50,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Developer",
        office: "Edinburgh",
        age: 42,
        startDate: "2010/12/22",
        salary: "$92,575",
    },
    {
        id: 51,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Systems Administrator",
        office: "New York",
        age: 59,
        startDate: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 52,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Senior Marketing Designer",
        office: "London",
        age: 43,
        startDate: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 53,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Regional Director",
        office: "London",
        age: 47,
        startDate: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 54,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Sales Assistant",
        office: "San Francisco",
        age: 59,
        startDate: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 55,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Secretary",
        office: "San Francisco",
        age: 41,
        startDate: "2010/02/12",
        salary: "$109,850",
    },
    {
        id: 56,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Office Manager",
        office: "San Francisco",
        age: 51,
        startDate: "2008/12/16",
        salary: "$164,500",
    },
    {
        id: 57,
        image: '7.jpg',
        name: 'Finn Camacho',
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        age: 43,
        startDate: "2013/02/01",
        salary: "$75,650",
    },
];

export const filterDataTableColumns: TableColumn<FilterDataTableProp>[] = [
    {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        center: false,
        cell: (row) => (<CustomNameImage src={row.image} name={row.name} />)
    },
    {
        name: "Position",
        selector: (row) => row["position"],
        sortable: true,
        center: false,
    },
    {
        name: "Office",
        selector: (row) => row["office"],
        sortable: true,
        center: false,
    },
    {
        name: "Age",
        selector: (row) => row["age"],
        sortable: true,
        center: false,
    },
    {
        name: "Start Date",
        selector: (row) => row["startDate"],
        sortable: true,
        center: false,
    },
    {
        name: "Salary",
        selector: (row) => row["salary"],
        sortable: true,
        center: false,
    },
];