import React from 'react'
import AddPostContainer from '../../../../Components/Miscellaneous/Blog/AddPost'

export default function AddPost() {
    return (
        <div className='page-body'>
            <AddPostContainer />
        </div>
    )
}
