import React from 'react'
import BasicInitContainer from '../../../../Components/Tables/DataTables/BasicInit'

export default function BasicInit() {
  return (
    <div className='page-body'>
        <BasicInitContainer/>
    </div>
  )
}
