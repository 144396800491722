import React from 'react'
import InputGroupsContainer from '../../../../Components/Forms/FormControls/InputGroups'

export default function InputGroups() {
  return (
    <div className='page-body'>
        <InputGroupsContainer/>
    </div>
  )
}
