import React from 'react'
import Invoice6Container from '../../../../../Components/Applications/ECommerce/Invoices/Invoice6'

export default function Invoice6() {
    return (
        <div className='page-body'>
            <Invoice6Container />
        </div>
    )
}
