import React from 'react'
import TourContainer from '../../../Components/BonusUi/Tour'

export default function Tour() {
  return (
    <div className='page-body'>
        <TourContainer/>
    </div>
  )
}
