import React from 'react'
import Invoice2Container from '../../../../../Components/Applications/ECommerce/Invoices/Invoice2'

export default function Invoice2() {
  return (
    <div className='page-body'> 
        <Invoice2Container/>
    </div>
  )
}
