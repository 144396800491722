import React from 'react'
import CommonErrorPage from '../Common/CommonErrorPage'

export default function ErrorPage5() {
    return (
        <CommonErrorPage
            title='500'
            color='secondary'
        />
    )
}
