import React from 'react'
import Invoice4Container from '../../../../../Components/Applications/ECommerce/Invoices/Invoice4'

export default function Invoice4() {
    return (
        <div className='page-body'>
            <Invoice4Container />
        </div>
    )
}
