export const calendarInitialState = {
    calendarEvents: [
        {
            title: 'Atlanta Monster',
            start: new Date('2022-04-04 00:00'),
            id: '99999998',
        },
        {
            title: 'My Favorite Murder',
            start: new Date('2022-04-05 00:00'),
            id: '99999999',
        },
    ],
    events: [
        { title: 'Birthday Party', id: '1', icon: 'birthday-cake' },
        { title: 'Meeting With Team.', id: '2', icon: 'user' },
        { title: 'Tour & Picnic', id: '3', icon: 'plane' },
        { title: 'Reporting Schedule', id: '4', icon: 'file-text' },
        { title: 'Lunch & Break', id: '5', icon: 'briefcase' },
    ],
};