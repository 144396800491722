import React from 'react'
import DropzoneContainer from '../../../Components/BonusUi/Dropzone'

export default function Dropzone() {
  return (
    <div className='page-body'>
        <DropzoneContainer/>
    </div>
  )
}
