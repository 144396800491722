import React from 'react'
import RibbonsContainer from '../../../Components/BonusUi/Ribbons'

export default function Ribbons() {
    return (
        <div className='page-body'>
            <RibbonsContainer />
        </div>
    )
}
