import React from 'react'
import APIContainer from '../../../../Components/Tables/DataTables/API'

export default function API() {
  return (
    <div className='page-body'>
        <APIContainer/>
    </div>
  )
}
