import React from 'react'
import ApplyJobsContainer from '../../../../Components/Miscellaneous/JobSearch/ApplyJobs'

export default function ApplyJobs() {
    return (
        <div className='page-body'>
            <ApplyJobsContainer />
        </div>
    )
}
