import React from 'react'
import ProgressContainer from '../../../Components/UiKits/Progress'

export default function Progress() {
  return (
    <div className='page-body'>
        <ProgressContainer/>
    </div>
  )
}
