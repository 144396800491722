import React from 'react'
import AdvanceInitContainer from '../../../../Components/Tables/DataTables/AdvanceInit'

export default function AdvanceInit() {
  return (
    <div className='page-body'>
        <AdvanceInitContainer/>
    </div>
  )
}
