import React from 'react'
import TouchspinContainer from '../../../../Components/Forms/FormWidgets/Touchspin'

export default function Touchspin() {
  return (
    <div className='page-body'>
        <TouchspinContainer/>
    </div>
  )
}
