import { SidebarMenuType } from "../../Types/Layout.type";

export const menuList: SidebarMenuType[] = [
    {
        title: '- General',
        menu: [
            {
                id: 1,
                title: 'Dashboard',
                icon: 'home',
                badge: true,
                badgeName: "",
                badgeColor: "light-primary",
                url: `${process.env.PUBLIC_URL}/dashboard/default`,
                type: 'sub',
                active: false,
            },
            {
                id: 2,
                title: 'Widgets',
                icon: 'widget',
                type: 'sub',
                active: false,
                menu: [
                    {
                        title: 'General',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/widgets/general_widget`,
                    },
                    {
                        title: 'Chart',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/widgets/chart_widget`,
                    },
                ],
            },
        ],
    },
    {
        title: '- Applications',
        menu: [
            {
                id: 3,
                title: 'Project',
                type: 'sub',
                icon: 'project',
                badge: true,
                badgeName: "New",
                badgeColor: "light-secondary",
                active: false,
                menu: [
                    {
                        title: 'Project List',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/project/project_list`,
                    },
                    {
                        title: 'Project Create',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/project/create_project`,
                    },
                ],
            },
            {
                id: 4,
                title: 'File Manager',
                url: `${process.env.PUBLIC_URL}/applications/file_manager`,
                icon: 'file',
                type: 'link'
            },
            {
                id: 5,
                title: 'Ecommerce',
                icon: 'ecommerce',
                type: 'sub',
                active: false,
                menu: [
                    {
                        title: 'Add Products',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/add_products`,
                    },
                    {
                        title: 'Product',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/product`,
                    },
                    {
                        title: 'Category Page',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/category_page`,
                    },
                    {
                        title: 'Product Page',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/product_page`,
                    },
                    {
                        title: 'Product list',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/product_list`,
                    },
                    {
                        title: 'Payment Details',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/payment_details`,
                    },
                    {
                        title: 'Order History',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/order_history`,
                    },
                    {
                        title: 'Invoices',
                        type: 'sub',
                        menu: [
                            {
                                title: 'Invoice-1',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_1`,
                            },
                            {
                                title: 'Invoice-2',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_2`,
                            },
                            {
                                title: 'Invoice-3',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_3`,
                            },
                            {
                                title: 'Invoice-4',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_4`,
                            },
                            {
                                title: 'Invoice-5',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_5`,
                            },
                            {
                                title: 'Invoice-6',
                                type: 'link',
                                url: `${process.env.PUBLIC_URL}/invoice/invoice_6`,
                            },
                        ]
                    },
                    {
                        title: 'Cart',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/cart`,
                    },
                    {
                        title: 'Wishlist',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/wishlist`,
                    },
                    {
                        title: 'Checkout',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/checkout`,
                    },
                    {
                        title: 'Pricing',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/ecommerce/pricing`,
                    },
                ],
            },
            {
                id: 6,
                title: 'Letter Box',
                url: `${process.env.PUBLIC_URL}/applications/email/letter_box`,
                icon: 'email',
                type: 'link',
            },
            {
                id: 7,
                title: 'Chats',
                icon: 'chat',
                type: 'sub',
                active: false,
                menu: [
                    {
                        title: 'Private Chat',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/chat/chat_private`,
                    },
                    {
                        title: 'Group chat',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/chat/chat_group`,
                    },
                ],
            },
            {
                id: 8,
                title: 'Users',
                icon: 'user',
                type: 'sub',
                bookmark: true,
                active: false,
                menu: [
                    {
                        title: 'Users Profile',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/users/user_profile`,
                    },
                    {
                        title: 'Users Edit',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/users/edit_profile`,
                    },
                    {
                        title: 'Users Cards',
                        type: 'link',
                        url: `${process.env.PUBLIC_URL}/applications/users/user_cards`,
                    },
                ],
            },
            {
                id: 9,
                title: 'Bookmarks',
                url: `${process.env.PUBLIC_URL}/applications/bookmark`,
                bookmark: true,
                type: 'link',
                icon: 'bookmark',
            },
            {
                id: 10,
                title: 'Contacts',
                icon: 'contact',
                url: `${process.env.PUBLIC_URL}/applications/contacts`,
                type: 'link',
            },
            {
                id: 11,
                title: 'Tasks',
                url: `${process.env.PUBLIC_URL}/applications/task`,
                icon: 'task',
                type: 'link',
            },
            {
                id: 12,
                title: 'Calendar',
                url: `${process.env.PUBLIC_URL}/applications/calendar_basic`,
                icon: 'calendar',
                type: 'link',
            },
            {
                id: 13,
                title: 'Social App',
                url: `${process.env.PUBLIC_URL}/applications/social_app`,
                bookmark: true,
                icon: 'social',
                type: 'link'
            },
            {
                id: 14,
                title: 'To-Do',
                url: `${process.env.PUBLIC_URL}/applications/to_do`,
                bookmark: true,
                icon: 'to-do',
                type: 'link',
            },
            {
                id: 15,
                title: 'Search Result',
                url: `${process.env.PUBLIC_URL}/applications/search`,
                icon: 'search',
                type: 'link'
            },
        ],
    },
];