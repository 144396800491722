import React from 'react'
import ImageHoverContainer from '../../../../Components/Miscellaneous/Gallery/ImageHoverEffects'

export default function ImageHoverEffects() {
    return (
        <div className='page-body'>
            <ImageHoverContainer />
        </div>
    )
}
