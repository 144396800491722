import React from 'react'
import RangeSliderContainer from '../../../Components/BonusUi/RangeSlider'

export default function RangeSlider() {
  return (
    <div className='page-body'>
        <RangeSliderContainer/>
    </div>
  )
}
