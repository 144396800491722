import React, { useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';

export default function CustomizingConjunction(this: any) {
    const [startDate, setStartDate] = useState(new DateObject());
    const handleChange = (date: DateObject) => {
        setStartDate(date);
    };

    return (
        <DatePicker
            inputClass="form-control"
            multiple
            dateSeparator="::"
            value={this.state.startDate}
            onChange={this.handleChangeStart}
        />
    )
}
