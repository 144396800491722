import React from 'react'
import ListsContainer from '../../../Components/UiKits/Lists'

export default function Lists() {
  return (
    <div className='page-body'>
        <ListsContainer/>
    </div>
  )
}
