import React from 'react'
import Invoice1Container from '../../../../../Components/Applications/ECommerce/Invoices/Invoice1'

export default function Invoice1() {
  return (
    <div className='page-body'>
        <Invoice1Container/>
    </div>
  )
}
