import React from 'react'
import GalleryDescriptionContainer from '../../../../Components/Miscellaneous/Gallery/GalleryDescription'

export default function GalleryDescription() {
    return (
        <div className='page-body'>
            <GalleryDescriptionContainer />
        </div>
    )
}
